import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from 'components/Modal';
import { trackModalOpened, trackModalClosed } from 'analytics/modal-tracking';
import { getCustomerId } from 'redux/modules/sessions/selectors';
import { useClientSideMediaQuery } from 'hooks/use-client-side-media-query';
import { BREAKPOINTS } from 'constants/grid';
import { modalSeverityValues } from 'constants/modals';
import classnames from 'classnames';

import styles from 'components/Product/Image/ProductImage.scss';

const ProductImage = ({
  name,
  placement,
  position,
  url,
  noMagnifier,
  mainImageInMultipleImagery,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const customerId = useSelector(state => getCustomerId(state));
  const isMobile = useClientSideMediaQuery({ maxWidth: BREAKPOINTS.sm });

  const getImgUrl = (int = 0) => {
    const imgUrl = url;
    return imgUrl.replace('/9/', `/${int}/`).replace('_9.jpg', `_${int}.jpg`);
  };

  const openModal = () => {
    trackModalOpened({
      id: 'productImageModal',
      severity: modalSeverityValues.INFO,
      title: name,
      customerId,
    });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    trackModalClosed({
      id: 'productImageModal',
      severity: modalSeverityValues.INFO,
      title: name,
      customerId,
    });
    setModalIsOpen(false);
  };

  if (!url) return null;

  const isProductDetails = placement === 'productDetails';

  const loadingPosition = isMobile ? 2 : 12;
  const loading = position > loadingPosition ? 'lazy' : undefined;

  return (
    <picture className={styles[placement]}>
      <div className={isProductDetails ? styles.detailsContainer : null}>
        {!isProductDetails && <source media="(max-width: 543px)" srcSet={url} />}
        <img
          className={classnames({ [styles.withBigMax]: mainImageInMultipleImagery })}
          src={getImgUrl(isProductDetails ? 11 : 3)}
          alt={name}
          loading={loading}
        />
      </div>
      {isProductDetails && !noMagnifier && (
        <div>
          <button
            aria-label="View Larger product image"
            className={styles.viewLargerImage}
            onClick={openModal}
            id="largerImageOpen"
            type="button"
          />
          <Modal titleText={name} isOpen={modalIsOpen} closeModal={closeModal}>
            <img src={getImgUrl(4)} alt={name} loading="lazy" />
          </Modal>
        </div>
      )}
    </picture>
  );
};

ProductImage.displayName = 'ProductImage';

ProductImage.propTypes = {
  name: PropTypes.string,
  placement: PropTypes.string,
  position: PropTypes.number,
  url: PropTypes.string.isRequired,
  noMagnifier: PropTypes.bool,
  mainImageInMultipleImagery: PropTypes.bool,
};

ProductImage.defaultProps = {
  name: undefined,
  placement: undefined,
  position: undefined,
  noMagnifier: false,
  mainImageInMultipleImagery: false,
};

export default ProductImage;
